<template>
  <div
    v-if="rowsLength"
    class="lightGrey pa-4 d-flex align-center justify-space-between">
    <img
      :class="[isLoadingImages ? 'not-allowed' : 'pointer']"
      src="@/assets/icons/chevron-left-icon.svg"
      height="12"
      width="12"
      @click="arrowControl('arrowLeft')">

    <slot />

    <img
      :class="[isLoadingImages ? 'not-allowed' : 'pointer']"
      src="@/assets/icons/chevron-right-icon.svg"
      height="12"
      width="12"
      @click="arrowControl('arrowRight')">
  </div>
</template>
<script>
import {
  mapGetters, mapMutations, mapState,
} from 'vuex';

import { TYPE_READONLY } from '@/constants';

export default {
  name: 'ProjectDetailsRowExpandedViewRowsSwitcher',
  props: {
    isLoadingImages: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      rows: 'ScheduleRows/mappedDataRows',
    }),
    ...mapState({
      modalRowId: state => state.ProjectDetailsTableSchedule.modalRowId,
    }),
    selected: {
      get() {
        return this.modalRowId;
      },
      set(val) {
        this.changeProductModalVariant(TYPE_READONLY);
        const value = val ? val : this.modalRowId;
        this.setModalRowId(value);
      },
    },
    selectedIndex() {
      const { rows = [], selected } = this;
      const index = rows.findIndex(({ id } = {
      }) => id === selected);
      if (index === -1) return 0;
      return index;
    },
    rowsLength() {
      const { rows = [] } = this;
      return rows.length;
    },
    lastRowIndex() {
      const { rowsLength = 0 } = this;
      if (!rowsLength) return 0;
      return rowsLength - 1;
    },
  },
  methods: {
    ...mapMutations({
      setModalRowId: 'ProjectDetailsTableSchedule/setModalRowId',
      changeProductModalVariant: 'Collections/changeProductModalVariant',
    }),
    arrowControl(method) {
      if (this.isLoadingImages) {
        return;
      }
      if (!method) return;
      this[method]();
    },
    arrowLeft() {
      const { selectedIndex: idx, rows, lastRowIndex: last } = this;
      let index;
      if (idx <= 0) {
        index = last;
      } else {
        index = idx - 1;
      }
      this.selected = rows[index]?.id;
    },
    arrowRight() {
      const { selectedIndex: idx, rows, lastRowIndex: last } = this;
      let index;
      if (idx >= last) {
        index = 0;
      } else {
        index = idx + 1;
      }
      this.selected = rows[index]?.id;
    },
  },
};
</script>
